import React from "react"
import Layout from "../../components/global/layout"
import { CardCarousel } from "../../components/block/CardCarousel"
import { urls } from "../../data/urls"

const cards = [
  {
    type: "text",
    to: null,
    title:
      "In a 2019 survey, 45% of women and 59% of men couldn't label the vagina on an anatomical drawing.",
    backgroundColor: "peach",
    textColor: "wine",
    withSkip: true,
  },
  {
    type: "text",
    to: null,
    title: "Can you believe it?",
    backgroundColor: "apricot",
    textColor: "wine",
    withSkip: true,
  },
  {
    type: "text",
    to: null,
    title:
      "For a start, many of us talk about the genitals as the vagina, but that's only part of the picture.",
    backgroundColor: "wine",
    textColor: "apricot",
    withSkip: true,
  },
  {
    type: "text",
    to: null,
    title:
      "The vagina is actually a muscular tube inside the body that leads from the vulva up to the cervix.",
    backgroundColor: "apricot",
    textColor: "wine",
    withSkip: true,
  },
  {
    type: "video",
    to: urls.KYV.NEXT,
    linkText: "Get Started",
    url: "https://player.vimeo.com/video/461739889?dnt=1",
  },
]

export default function index() {
  return (
    <Layout>
      <CardCarousel cards={cards} />
    </Layout>
  )
}
